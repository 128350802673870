<template>
    <div>
        <section class="home" style="position:relative;">
            <section class="slider-hero">
                <div class="overlay"></div>
                <div class="hero-slider">
                    <b-carousel id="carousel-1" v-model="slideItens" :interval="8000" fade controls indicators
                        img-width="1920" img-height="750" class="item work">
                        <b-carousel-slide v-for="slid in slider" :key="slid.institucionalId"
                            :img-src="$Url + slid.institucionalImg[0].urlImagem" class="img img2 d-flex align-items-center">
                        </b-carousel-slide>
                    </b-carousel>
                </div>
            </section>
           
            <!-- SECTION -->
            <div class="section">
                <!-- container -->
                <div class="container"> 
                    <!-- row -->
                    <div class="row">
                        <!-- shop -->
                        <div class="col-md-4 col-xs-6" v-for="itens in itensHome" :key="itens.institucionalId">
                            <div class="shop">
                                <div class="shop-img">
                                    <img :src="$Url + itens.institucionalImg[0].urlImagem" :alt="itens.nome">
                                </div>
                                <div class="shop-body">
                                    <h3 style="font-size: 24px !important;font-weight: 800 !important;">{{ itens.nome }}
                                    </h3>
                                    <a v-if="itens.nome != 'Trabalhe Conosco'" :href="itens.linkBtn1" class="cta-btn"
                                        style="color:#ffffff !important;">Saiba mais <i
                                            class="fa fa-arrow-circle-right"></i></a>
                                            <a v-else href="https://industriadepecasinpelsa.pandape.infojobs.com.br/?_gl=1*b7tpev*_gcl_aw*R0NMLjE3Mjk2ODMyNjguQ2owS0NRand2ZUs0QmhENEFSSXNBS3k2cE1Jc255dFJrb0tqb3hINVF2YjZ3SkFWMEJ4TFVRdDRsazFnOWprdmE1STNtVWZRXzRxeVZfZ2FBZ3MtRUFMd193Y0I.*_gcl_au*MTc5MTI4NzQ5Ny4xNzI5NjgzMjY4*_ga*Nzc0ODAwMjk2LjE3MjA1MjIyNTE.*_ga_BLEQGRBWCW*MTczNDM1MjkxNS40MDcuMS4xNzM0MzUyOTM4LjM3LjAuMA" class="cta-btn"
                                        style="color:#ffffff !important;">Saiba mais <i
                                            class="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                        </div>
                        <!-- /shop -->
                    </div>
                    <!-- /row -->
                </div>
                <!-- /container -->
            </div>
            <!-- /SECTION -->
            <div class="section">
                <!-- container -->
                <div class="container">
                    <!-- row -->
                    <div class="row">

                        <!-- section title -->
                        <div class="col-md-12" style="text-align: center;">
						<div class="section-title">
							<h3 class="title" style="font-size: 25px;font-weight: 800;">Caixas de Transmissão</h3>
							<div class="section-nav">
							</div>
						</div>
					</div>
                        <!-- /section title -->

                        <!-- Products tab & slick -->
                        <div class="col-md-12">
                            <div class="row">
                               <carousel autoplay :autoplayTimeout=4000 autoplayHoverPause paginationEnabled :paginationSize=10 :loop="true" :navigationEnabled="true"
                               :perPageCustom="[[340, 1],[360, 1],[768, 3], [1024,4], [1600,4]]">
                                  <slide v-for="item in MenuSegmentosProd" :key="item.segmentoProdNivel1Id" style="padding: 10px;">
                                        <div class="product">
                                            <router-link
                                                :to="'/produtos/' + item.segmentoProdNivel1Id + '/' + item.nomeSegmento.replaceAll(' ', '-').replaceAll('/', '')">
                                                <div class="product-img">
                                                    <img v-if="item.imagens.length == 2" :src="$Url + item.imagens[0].urlImagem"  @mouseover="imagemReset = item.imagens[0].urlImagem; item.imagens[0].urlImagem = item.imagens[1].urlImagem;show = !show;"  @mouseout="item.imagens[0].urlImagem = imagemReset;show = !show;" style="height: 160px;"/>
                                                    <img v-else-if="item.imagens.length >= 1" v-bind:src="$Url + item.imagens[0].urlImagem" alt="" style="height: 160px;">
                                                    <img v-else src="/img/indisponivel.jpg" alt="" style="height: 160px;"/> 
                                                </div>
                                            </router-link>
                                            <div class="product-body">
                                                <p class="product-category">Caixas de Transmissão</p>
                                                <h3 class="product-name">
                                                    <router-link
                                                        :to="'/produtos/' + item.segmentoProdNivel1Id + '/' + item.nomeSegmento.replaceAll(' ', '-').replaceAll('/', '')"
                                                        style="font-size: 14px;color: #2B2D42;font-weight: 500;">
                                                        {{ item.nomeSegmento }}
                                                    </router-link>
                                                </h3>
                                            </div>
                                            <div class="add-to-cart">
                                                <button class="add-to-cart-btn"
                                                    @click="$redirect_reload('/produtos/' + item.segmentoProdNivel1Id + '/' + item.nomeSegmento.replaceAll(' ', '-').replaceAll('/', ''))">
                                                    <i class="fa fa-angle-right"></i> + Detalhes
                                                </button>
                                            </div>
                                        </div>
                                   </slide>
                                </carousel>
                            </div>
                        </div>
                        <!-- Products tab & slick -->
                    </div>
                    <!-- /row -->
                </div>
                <!-- /container -->
            </div>
            <!-- /SECTION -->
            <!-- SECTION -->
            <div class="section">
                <!-- container -->
                <div class="container">
                    <!-- row -->
                    <div class="row">
                        <div class="col-md-12 col-xs-12" style="text-align: center;padding-bottom: 30px;">
                            <h3 class="title">Útimos Posts</h3>
                        </div>
                        <div class="col-md-4 col-xs-6" style="text-align: center;" v-for="artigo in artigos"
                            :key="artigo.institucionalId">
                            <div class="product-img">
                                <a href="#"
                                    @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ', '-').replaceAll('/', ''))">
                                    <img v-if="artigo.institucionalImg" style="width: 100%;"
                                        :src="$Url + artigo.institucionalImg[0].urlImagem" alt="">
                                    <img v-else :src="$Url + '/imagens/indisponivel.pjg'" style="height: 100%;" alt="">
                                </a>
                            </div>
                            <a href="#"
                                @click="$redirect_reload('/blogDetalhe/' + artigo.institucionalId + '/' + artigo.nome.replaceAll(' ', '-').replaceAll('/', ''))">
                                <p class="product-category" style="font-size: 18px;padding-top: 10px;color:#2B2D42;">
                                    {{ artigo.nome }}</p>
                            </a>
                        </div>
                    </div>
                    <!-- /row -->
                </div>
                <!-- /container -->
            </div>
            <!-- /SECTION -->
        </section>
        <!-- NEWSLETTER -->
        <div id="newsletter" class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-12">
                        <div class="newsletter">
                            <p><strong>Encontre a Inpel nas Redes</strong></p>
                            <ul class="newsletter-follow mt-3">
                                <li v-if="facebook != ''">
                                    <a :href="facebook" target="_blank"><i class="fa fa-facebook"></i></a>
                                </li>
                                <li v-if="linkedin != ''">
                                    <a :href="linkedin" target="_blank"><i class="fa fa-linkedin"></i></a>
                                </li>
                                <li v-if="instagram != ''">
                                    <a :href="instagram" target="_blank"><i class="fa fa-instagram"></i></a>
                                </li>
                                <li v-if="youtube != ''">
                                    <a :href="youtube" target="_blank"><i class="fa fa-youtube"></i></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /NEWSLETTER -->
    </div>
</template>

<script>
import { VMoney } from 'v-money'
import moment from 'moment'
import { Carousel, Slide } from 'vue-carousel';
//import Slider from './../shared/BannerSlider2.vue'

export default {
    components: {
        Carousel,
        Slide,
       // Slider
    },
    data() {
        return {
            imagemReset:'',
            ItensHome: [],
            ItemHome: {},
            slide: 0,
            sliding: null,
            itensHome: [],
            money: {
                decimal: ',',
                thousands: '.',
                precision: 2,
                masked: true
            },
            textobusca: '',
            slideItens: '',
            slider: [],
            Produtos: [],
            artigos: [],
            facebook: '',
            linkedin: '',
            instagram: '',
            youtube: '',
            MenuSegmentos: [],
            MenuSegmento: {},
            MenuSegmentosProd: [],
        }
    },
    directives: { money: VMoney },
    methods: {
        slickPrev() {
        this.$refs.slickRef.prev();
        },
        slickNext() {
        this.$refs.slickRef.next();
        },
        currentDateTime(vdate) {
            return moment(vdate).format('DD/MM/YYYY')
        },
        ValidaBusca() {
            if (this.textobusca != '') {
                this.$redirect("/produtosbusca/" + this.textobusca);
            }
            else {
                this.$mensagem_normal("Digite uma palavra-chave e clique na lupa para pesquisar.");
            }

        },
        initCarousel() {
            this.sliding = true
        },
        endSlide() {
            this.sliding = false
        },
    },
    created() {

        this.$http
            .get(this.$apiUrl + "/institucional/telas/slider")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.slider = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                }).reverse();

                /*this.slider.forEach(element => {
                    this.fluxImages.push(element.institucionalImg[0].urlImagem)
                });*/
            });


        this.$http
            .get(this.$apiUrl + "/institucional/telas/itensdestaque")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.itensHome = tiu2.filter(x => x.visivel).sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                });
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/notícias")
            .then((res) => res.json())
            .then((retornoAPI) => {
                this.artigos = retornoAPI.filter(x => x.visivel).sort(function (a, b) {
                    if (a.data1 > b.data1) {
                        return 1;
                    }
                    if (a.data1 < b.data1) {
                        return -1;
                    }
                    return 0;
                }).reverse();

            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/Links%20Redes%20Sociais")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.redes = tiu2;
                if (this.redes != '') {
                    this.facebook = this.redes[0].campoExtra1
                    this.linkedin = this.redes[0].campoExtra2
                    this.instagram = this.redes[0].linkBtn1
                    this.youtube = this.redes[0].linkBtn2
                }
            });

        this.$http
            .get(this.$apiUrl + "/SegmentoProdNivel1/")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.MenuSegmentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                    if (a.nomeSegmento > b.nomeSegmento) {
                        return 1;
                    }
                    if (a.nomeSegmento < b.nomeSegmento) {
                        return -1;
                    }
                    return 0;
                });
                this.MenuSegmentosProd = this.MenuSegmentos;
            });

    },
    mounted() {

    },
}

</script>

<style>
@import '/css/produtos.css';

.newsletter-follow li a {
    color: #2B2D42;
}

.carousel-caption {
    position: absolute !important;
    right: 15% !important;
    bottom: 11.25rem !important;
    left: 10% !important;
    padding-top: 1.25rem !important;
    padding-bottom: 1.25rem;
    color: #fff;
    text-align: left !important;
}

.bg-color {
    background-color: #993366;
}

.imagem_produto {
    max-height: 250px;
}

.product_item {
    width: 100%;
    transition: all .2s ease-in;
    background-color: transparent;
}

.vfigure {
    position: absolute;
    float: left;
    top: 20px;
    height: 40px;
    z-index: 999;
    text-align: center;
}

.vfigcaption {
    font-size: 12px;
    left: 0px;
    top: 17px;
    color: #000000;
    width: 50px;
    position: absolute;
}

.vfigcaption2 {
    font-size: 12px;
    left: 0px;
    top: 17px;
    color: #000000;
    width: 50px;
    position: absolute;
}

.vfigure2 {
    position: absolute;
    float: left;
    top: 20px;
    height: 40px;
    z-index: 999;
    text-align: center;
}

.VueCarousel-wrapper {
    width: 100%;
    height: 360px !important;
    position: relative;
    overflow: hidden;
}

.VueCarousel-pagination div{
    margin-top: 0px !important;
}


/********************
    Responsive
    ********************/

/* Smartphones */
@media (max-width:767px) {
    .product_item {
        left: 0px;
    }

    .vfigure {
        position: absolute;
        float: left;
        top: 65px;
        height: 40px;
        z-index: 999;
        text-align: center;
    }

    .vfigcaption {
        font-size: 12px;
        left: 0px;
        top: 17px;
        color: #000000;
        width: 50px;
        position: absolute;
    }

    .peq {
        font-size: 9px;
        left: 0px;
        top: 10px;
        color: #000000;
        width: 36px;
        position: absolute;
    }

    .imgcelet {
        width: 35px;
    }

    .vfigure2 {
        position: absolute;
        float: left;
        top: 65px;
        height: 40px;
        z-index: 999;
        left: 30px;
    }


    .vfigcaption2 {
        font-size: 12px;
        left: 0px;
        top: 17px;
        color: #000000;
        width: 50px;
        position: absolute;
    }

    .new_arrivals_title {
        position: absolute;
        left: 0;
        top: 20px;
        font-size: 24px;
        font-weight: 500;
        margin-bottom: -29px;
    }

    .VueCarousel-wrapper {
    width: 100%;
    height: 334px !important;
    position: relative;
    overflow: hidden;
}
}

/* Tudo, menos desktop */
@media (max-width:1199px) {
    .product_item {
        height: min-content;
        padding-top: 60px;
        padding-bottom: 40px;
    }

    .product_price {
        margin-top: 0px;
    }
}

/* Desktop + Notbooks */
@media (min-width:1200px) {
    .product_item_alt {
        min-height: 240px;
    }
}

p {
    margin-top: 0;
    margin-bottom: 0rem !important;
}

</style>