<template>
    <!-- FOOTER -->
    <footer id="footer">
        <!-- top footer -->
        <div class="section">
            <!-- container -->
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col-md-3 col-xs-6">
                        <div class="footer" v-if="informacoes != ''">
                            <h3 class="footer-title">Inpel</h3>
                            <p class="notranslate" v-html="informacoes[0].descricao1">
                            </p>
                            <ul class="footer-links notranslate">
                                <li><a :href="informacoes[0].linkBtn1" target="_blank"><i
                                            class="fa fa-whatsapp"></i>{{ informacoes[0].textoBtn1 }}</a></li>
                                <li><a href="#"><i class="fa fa-envelope-o"></i>{{ informacoes[0].textoBtn2 }}</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-3 col-xs-6">
                        <div class="footer">
                            <h3 class="footer-title">Institucional</h3>
                            <ul class="footer-links">
                                <li><router-link to="/sobre">Sobre</router-link></li>
                                <li><router-link to="/segmentos">Caixas de Transmissão</router-link></li>
                                <li><router-link to="/blog">Blog</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-3 col-xs-6">
                        <div class="footer">
                            <h3 class="footer-title">Contato</h3>
                            <ul class="footer-links">
                                <li><a :href="linkcar" target="_blank">Carreira</a></li>
                                <li><router-link to="/contato">Contato</router-link></li>
                                <li><router-link to="/sugestoes">Reclamações/Sugestões</router-link></li>
                            </ul>
                        </div>
                    </div>

                    <div class="clearfix visible-xs"></div>

                    <div class="col-md-3 col-xs-6" style="display: none;">
                        <div class="footer">
                            <h3 class="footer-title">Departamentos</h3>
                            <ul class="footer-links">
                                <li v-for="linhaRet in departamentos" :key="linhaRet.institucionalId">
                                    <router-link
                                        :to="'/departamentos/' + linhaRet.institucionalId + '/' + linhaRet.nome.replaceAll(' ', '-').replaceAll('/', '')">{{ linhaRet.nome }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-md-3 col-xs-6">
                        <div class="footer">
                            <h3 class="footer-title">LGPD</h3>
                            <ul class="footer-links">
                                <li v-for="linhaRet2 in infolgpd" :key="linhaRet2.institucionalId">
                                    <router-link
                                        :to="'/InformacoesLgpd/' + linhaRet2.institucionalId + '/' + linhaRet2.nome.replaceAll(' ', '-').replaceAll('/', '')">{{ linhaRet2.nome }}</router-link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /top footer -->

        <!-- bottom footer -->
        <div id="bottom-footer" class="section" style="padding-top: 0px;padding-bottom: 10px;">
            <div class="container">
                <!-- row -->
                <div class="row">
                    <div class="col text-center py-4 mt-3">
                        <a href="https://avancedigital.com.br/" target="_blank"><img src="/img/avanceBranco.png"
                                alt=""></a>
                    </div>
                </div>
                <!-- /row -->
            </div>
            <!-- /container -->
        </div>
        <!-- /bottom footer -->
    </footer>
    <!-- /FOOTER -->
</template>

<script>
export default {
    data() {
        return {
            Segmentos: [],
            informacoes: [],
            Face: [],
            Insta: [],
            Yout: [],
            Linke: [],
            logo_footer: '@/assets/img/10.png',
            logSelo: '',
            resultSelo: [],
            Retorno: [],
            linhaRet: {},
            departamentos: [],
            infolgpd: [],
            linkcarreiras:[],
            linkcar:'',
        }
    },
    created() {

        this.$http
            .get(this.$apiUrl + "/institucional/telas/rodapé")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.informacoes = tiu2;
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/infodepartamentos")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.departamentos = tiu2.filter(x => x.visivel).sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                });
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/infolgpd")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.infolgpd = tiu2.filter(x => x.visivel).sort(function (a, b) {
                    if (a.ordem > b.ordem) {
                        return 1;
                    }
                    if (a.ordem < b.ordem) {
                        return -1;
                    }
                    return 0;
                });
            });

            this.$http
            .get(this.$apiUrl + "/institucional/telas/linkcarreiras")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.linkcarreiras = tiu2;
                if (this.linkcarreiras != '') {
                    this.linkcar = this.linkcarreiras[0].linkBtn1;
                }
                this.linkcar = "https://industriadepecasinpelsa.pandape.infojobs.com.br/?_gl=1*b7tpev*_gcl_aw*R0NMLjE3Mjk2ODMyNjguQ2owS0NRand2ZUs0QmhENEFSSXNBS3k2cE1Jc255dFJrb0tqb3hINVF2YjZ3SkFWMEJ4TFVRdDRsazFnOWprdmE1STNtVWZRXzRxeVZfZ2FBZ3MtRUFMd193Y0I.*_gcl_au*MTc5MTI4NzQ5Ny4xNzI5NjgzMjY4*_ga*Nzc0ODAwMjk2LjE3MjA1MjIyNTE.*_ga_BLEQGRBWCW*MTczNDM1MjkxNS40MDcuMS4xNzM0MzUyOTM4LjM3LjAuMA";
            });
    },
    mounted() {

    },
    components: {
    },
}
</script>

<style>
.social-icon {
    background-color: #fff;
    border: 1px solid #fff;
    border-radius: 50%;
    margin-left: 8px;
    transition: transform .2s ease-in;
}

.social-icon:hover {
    transform: scale(1.1);
}

ol,
ul {
    padding-left: 0rem !important;
}
.notranslate {
  /* Define a propriedade pointer-events como 'none' para evitar que o conteúdo seja selecionado pelo usuário */
  pointer-events: none;
}
</style>