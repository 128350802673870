<template>
    <div>
        <nav id="navigation" class="hidden-xs hidden-sm">
            <!-- container -->
            <header class="hidden-xs hidden-sm">
            <!-- TOP HEADER -->
            <div id="top-header" class="hidden-xs hidden-sm">
                <div class="container">
                    <ul class="header-links pull-left">
                        <li><a href="#" class="notranslate"><i class="fa fa-phone"></i> {{ telefone }}</a></li>
                        <li><a href="#" class="notranslate"><i class="fa fa-envelope-o"></i> {{ emailtopo }}</a></li>
                        <li><a href="#" class="notranslate"><i class="fa fa-map-marker"></i> {{ endereco }}</a></li>
                    </ul>
                    <ul class="header-links pull-right">
                        <li><a href="#" @click="showModal = true"><i class="fa fa-map-o"></i> Selecione Idioma</a></li>
                        <li>               
                                <!-- Cart -->
                                <div class="dropdown" style="display: inline-flex;">
                                    <a href="#" @click="$redirect('/carrinho')">
                                        <i class="fa fa-shopping-cart"></i>
                                        <span>Orçamento</span>
                                    </a>
                                </div>
                                <!-- /Cart -->
                        </li>
                    </ul>
                </div>
            </div>
            <!-- /TOP HEADER -->
        </header>
            <div class="container" >
                <div class="row">
                    <div class="col-md-3">
                        <div class="header-logo" style="padding-top: 5px;">
                        <a href="#" @click="$redirect_reload('/')" class="logo">
                            <img src="/img/logo.jpg" class="img-responsive" style="height: 100px;" alt="">
                            <!--<img src="./img/logo.jpg" class="img-responsive" style="height: 100px;" alt="">-->
                        </a>
                    </div>
                    </div>
                    <div class="col-md-9">
                        <div class="newsletter">
                                    <form>
                                        <input class="input" v-model="textobusca" @keyup.enter="ValidaBusca()"
                                            placeholder="Digite Pesquisa" style="font-size: 13px !important;">
                                        <button class="newsletter-btn" @click.prevent="ValidaBusca()"><i
                                                class="fa fa-search"></i> Pesquisar</button>
                                    </form>
                                </div>
                        <!-- responsive-nav -->
                    <div id="responsive-nav">
                        <!-- NAV -->
                        <ul class="main-nav nav navbar-nav" style="line-height: 0px;padding-top: 10px;">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/sobre">Sobre</router-link></li>
                            <li><router-link to="/segmentos">Caixas de Transmissão</router-link></li>
                            <li><a :href="linkcar"
                                    target="_blank">Carreira</a></li>
                            <li><router-link to="/contato">Contato</router-link></li>
                            <li><router-link to="/blog">Blog</router-link></li>
                            <li><router-link to="/sugestoes">Reclamações/Sugestões</router-link></li>
                        </ul>
                        <!-- /NAV -->
                    </div>
                    <!-- /responsive-nav -->
                        </div>
                    
                </div>
            </div>
            <!-- /container -->
        </nav>

        <div class="container hidden-lg hidden-md">
            <div class="row nomargin">
                <div class="col-6">
                    <b-button v-b-toggle.sidebar-1 class="colorlib-nav-toggle" style="margin: 10px;"><i
                            class="fa fa-bars"></i>
                        Menu</b-button>
                </div>
                <div class="col-6">
                    <div class="logo">
                        <router-link router-link to="/">
                            <img src="/img/logo.jpg" class="img-responsive" alt="">
                            <!--<img src="./img/logo.jpg" class="img-responsive" style="height: 100px;" alt="">-->

                        </router-link>
                    </div>
                </div>
            </div>
            <b-sidebar id="sidebar-1" title="" bg-variant="light" text-variant="black" shadow >
                <div class="" style="background-color: #ffffff;">
                    <aside style="padding-top: 20px;">
                        <h1 id="colorlib-logo" style="display: block;text-align: center;">
                            <router-link to="/" class="scrollto"
                                style="color: #000;letter-spacing: 2px;padding-right: 0.3em;">
                                <center><img src="/img/logo.jpg" class="img-responsive" alt=""></center>
                            </router-link>
                        </h1>
                        <ul id="colorlib-main-menu" class="nav-menu" style="padding-top: 20px;text-align: left;">
                            <li style="list-style: none;padding: 5px;">
                                <router-link to="/" style="color: #2B2D42;">Home</router-link></li>
                            <li style="list-style: none;padding: 5px;"><router-link to="/sobre" style="color: #2B2D42;">Sobre</router-link></li>
                            <li style="list-style: none;padding: 5px;"><router-link to="/segmentos" style="color: #2B2D42;">Caixas de
                                    Transmissão</router-link></li>
                            <li style="list-style: none;padding: 5px;">
                                <a :href="linkcar"
                                    target="_blank" style="color: #2B2D42;">Carreira</a></li>
                            <li style="list-style: none;padding: 5px;"><router-link to="/contato" style="color: #2B2D42;">Contato</router-link>
                            </li>
                            <li style="list-style: none;padding: 5px;"><router-link to="/blog" style="color: #2B2D42;">Blog</router-link></li>
                            <li style="list-style: none;padding: 5px;"><router-link
                                    to="/sugestoes" style="color: #2B2D42;">Reclamações/Sugestões</router-link></li>
                        </ul>
                    </aside>
                </div>
                <div class="newsletter">
                                <form>
                                    <input class="input" v-model="textobusca" @keyup.enter="ValidaBusca()"
                                        placeholder="Digite Pesquisa">
                                    <button class="newsletter-btn" @click.prevent="ValidaBusca()"><i
                                            class="fa fa-search"></i> Pesquisar</button>
                                </form>
                            </div>
            </b-sidebar>
        </div>
        <Modal v-model="showModal" title="Languages">
          <Translator :countries="arrayOfCountries" @on-country-click="showModal = false" />
        </Modal>
    </div>
</template>
<script>
import { Translator } from 'vue-google-translate';
export default {
    data() {
        return {
            textobusca: '',
            usuario: {},
            WhatsFlutuante: [],
            cabecalho: [],
            telefone: '',
            emailtopo: '',
            endereco: '',
            linkcarreiras:[],
            linkcar:'',
            showModal: false,
      arrayOfCountries: [
        {
          code: 'en|pt',
          title: 'Português',
          flagIconUrl: '/img/brasilimg.jpg',
        },
        {
          code: "en|en",
          title: "Inglês",
          flagIconUrl: '/img/__English.png',
        },
        {
          code: "en|es",
          title: "Espanhol",
          flagIconUrl: '/img/__Spanish.png',
        },
      ],
        };
    },
    methods: {
        CheckLogin() {
            let usuarioTmp = {
                email: '',
                senha: '',
            }
            usuarioTmp = JSON.parse(this.$cookie.get(this.$UsuarioCookie));
            if (usuarioTmp != null) {
                let _usuario = {
                    email: usuarioTmp.email,
                    senha: usuarioTmp.senha,
                };
                this.$http.post(this.$apiUrl + "/clientes/login", _usuario).then(
                    (response) => {
                        // get body data
                        this.usuario = response.body;
                    },
                    (response2) => {
                        // error callback
                        this.$mensagem_erro(response2.body);

                    }
                );
            }
            else {
                this.usuario = '';
            }
        },
        Logout() {
            this.$cookie.delete(this.$UsuarioCookie);
            localStorage.removeItem(this.$UsuarioDados);
            this.$redirect_reload("/");
        },
        ValidaBusca() {
            if (this.textobusca != '') {
                this.$redirect("/produtos/" + this.textobusca);
            }
            else {
                this.$mensagem_normal("Digite uma palavra-chave e clique na lupa para pesquisar.");
            }

        },
    },
    created() {
        //this.CheckLogin();

            this.$http
            .get(this.$apiUrl + "/institucional/telas/linkcarreiras")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.linkcarreiras = tiu2;
                if (this.linkcarreiras != '') {
                    this.linkcar = this.linkcarreiras[0].linkBtn1;
                }
                this.linkcar = "https://industriadepecasinpelsa.pandape.infojobs.com.br/?_gl=1*b7tpev*_gcl_aw*R0NMLjE3Mjk2ODMyNjguQ2owS0NRand2ZUs0QmhENEFSSXNBS3k2cE1Jc255dFJrb0tqb3hINVF2YjZ3SkFWMEJ4TFVRdDRsazFnOWprdmE1STNtVWZRXzRxeVZfZ2FBZ3MtRUFMd193Y0I.*_gcl_au*MTc5MTI4NzQ5Ny4xNzI5NjgzMjY4*_ga*Nzc0ODAwMjk2LjE3MjA1MjIyNTE.*_ga_BLEQGRBWCW*MTczNDM1MjkxNS40MDcuMS4xNzM0MzUyOTM4LjM3LjAuMA";
            });

        this.$http
            .get(this.$apiUrl + "/institucional/telas/cabeçalho")
            .then((res2) => res2.json())
            .then((tiu2) => {
                this.cabecalho = tiu2;
                if (this.cabecalho != '') {
                    this.telefone = this.cabecalho[0].campoExtra1
                    this.emailtopo = this.cabecalho[0].campoExtra2
                    this.endereco = this.cabecalho[0].campoExtra3
                }
            });        
    },
    mounted() {
    },
    components: {
        Translator
    },
};

</script>

<style>

.notranslate {
  /* Define a propriedade pointer-events como 'none' para evitar que o conteúdo seja selecionado pelo usuário */
  pointer-events: none;
}

#navigation {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000; /* Garante que o menu esteja sempre acima de outros elementos */
    background-color: #fff; /* Define uma cor de fundo para o menu */
    /* Adicione quaisquer outros estilos desejados */
}




/* gets rid of the banner at the top of the page */
body {
  top: 0 !important;
}

/* get rids of the banner at the bottom of the web page */
.skiptranslate {
  display: none !important;
}


/* WHATSAPP FIXO */
.whatsapp-fixo {
    position: fixed;
    bottom: 2%;
    right: 0px;
    z-index: 99999999;
}

.navbar-nav {
    display: inline-block !important;
    flex-direction: column;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}


.navbar-light .navbar-nav .nav-link {
    color: #fff !important;
}

.navbar-light .navbar-toggler {
    border-color: #ffffff !important;
}

.navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(0, 0, 0, 1)' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e") !important;
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: end !important;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
}

.b-icon{
    font-size: 30px;
    color: #000000;
}

@media (max-width:767px) {
    .hidden-xs {
        display: none !important;
    }

    .newsletter form .newsletter-btn {
    width: 120px !important;
    height: 40px;
    font-weight: 700;
    background: #D10024;
    color: #FFF;
    border: none;
    border-radius: 0px 40px 40px 0px;
}

.newsletter form .input {
    width: calc(100% - 140px) !important;
    margin-right: -4px;
    border-radius: 40px 0px 0px 40px;
}
}

@media (min-width:768px) and (max-width:991px) {
    .hidden-sm {
        display: none !important;
    }
}

@media (min-width:992px) and (max-width:1199px) {
    .hidden-md {
        display: none !important;
    }
}

@media (min-width:1200px) {
    .hidden-lg {
        display: none !important;
    }
}
</style>

